* {box-sizing: border-box}

/*input:hover,*/
.btn:hover {
    opacity: 1;
}

/* add appropriate colors to fb, twitter and google buttons */
.fb {
    background-color: #3B5998;
    color: white;
}

.twitter {
    background-color: #55ACEE;
    color: white;
}

.google {
    background-color: #dd4b39;
    color: white;
}

.microsoft {
    background-color: #00a1f1;
    color: white;
}

.discord {
    /*background-color: #7289da;*/
    background-color: 	#2C2F33;
    color: white;
}

.instagram {
    /*background-color: #7289da;*/
    background-color: 	#2C2F33;
    color: white;
}

.twitter {
    /*background-color: #7289da;*/
    background-color: 	#14171A;
    color: white;
}

.snapchat {
    /*background-color: #FFFC00;  Yellow*/
    background-color: 	#000000;
    color: white;
}

.tiktok {
    /*background-color: #7289da;*/
    background-color: 	#2C2F33;
    color: white;
}

.youtube {
    /*background-color: #7289da;*/
    background-color: 	#b51414;
    color: white;
}

.twitch {
    /*background-color: #7289da;*/
    background-color: 	#9146FF;
    color: white;
}

.patreon {
    /*background-color: #7289da;*/
    background-color: 	#FF424D;
    color: white;
}

.steam {
    /*background-color: #7289da;*/
    background-color: 	#171a21;
    color: white;
}

.xbox {
    /*background-color: #7289da;*/
    background-color: 	#000000;
    color: white;
}

.playstation {
    /*background-color: #7289da;*/
    background-color: 	#000000;
    color: white;
}

.linkedin {
    /*background-color: #7289da;*/
    background-color: 	#0A66C2;
    color: white;
}


/* style the submit button */
input[type=submit] {
    background-color: #04AA6D;
    color: white;
    cursor: pointer;
}

input[type=submit]:hover {
    background-color: #45a049;
}

/* Two-column layout */
.col {
    float: left;
    width: 50%;
    margin: auto;
    padding: 0 50px;
    margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
    content: "";
    display: table;
    clear: both;
}

/* vertical line */
.vl {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    border: 2px solid #ddd;
    height: 175px;
}

/* text inside the vertical line */
.inner {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 8px 10px;
}

/* hide some text on medium and large screens */
.hide-md-lg {
    display: none;
}

/* bottom container */
.bottom-container {
    text-align: center;
    background-color: #666;
    border-radius: 0px 0px 4px 4px;
}

/* Responsive layout - when the screen is less than 650px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 650px) {
    .col {
        width: 100%;
        margin-top: 0;
    }
    /* hide the vertical line */
    .vl {
        display: none;
    }
    /* show the hidden text on small screens */
    .hide-md-lg {
        display: block;
        text-align: center;
    }
}