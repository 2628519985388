@import '~@fortawesome/fontawesome-free/css/all.min.css';

:root {

    --headerBackground: transparent;
    --headerFont: #3a3a3a;
    --headerIcons: #3a3a3a;
    --dropdownBackground:#595959;
    --subDropdownBackground:#797979;
    --dropdownFont: #ffffff;
    --dropdownHover: #0f590d;
}

.fa:hover {
    color: var(--hover);
}

/*.hamburger {*/
/*    content: ' ';*/
/*    position: relative;*/
/*    width: 20px;*/
/*    border: 2px solid var(--border);*/
/*    border-radius: 5px;*/
/*    transition: all 0.333333333333333s ease;*/
/*}*/

/*.hamburger:after,*/
/*.hamburger:before {*/
/*    content: ' ';*/
/*    position: absolute;*/
/*    left: 50%;*/
/*    width: 100%;*/
/*    border: 2px solid var(--border);*/
/*    border-radius: 5px;*/
/*    transform: translateX(-50%);*/
/*    transition-property: top, bottom, transform;*/
/*    transition-duration: 1s, 1s, 0.25s;*/
/*}*/

/*.hamburger:after {*/
/*    top: -8px;*/
/*}*/

/*.hamburger:before {*/
/*    bottom: -8px;*/
/*}*/

.header {
    display: flex;
    top: 0;
    height: 250px;
    width: 100%;
    z-index: 999;
    background-color: var(--headerBackground);
    justify-content: center;
    align-items: center;
}

.header-logo {
    width: 220px;
    height: auto;
}

/*.header-button {*/
/*    margin-top: 10px;*/
/*    margin-right: 15px;*/
/*    text-decoration: none;*/
/*    font-size: 40px;*/
/*    color: var(--headerFont);*/
/*    float: right;*/
/*    cursor: pointer;*/
/*    z-index: 10000;*/
/*}*/

.headerButton {
    margin-top: 10px;
    margin-right: 15px;
    text-decoration: none;
    font-size: 35px;
    color: var(--headerIcons);
    float: right;
    cursor: pointer;
    z-index: 9999;
}

.headerButton:hover {
    color: var(--hover);
}

.header-container {
    position: fixed;
    top: 0;
    width: 100%;
    height: 200px;
    /*background-color: transparent;*/
    background-color: #201e1e;
    z-index: 9999;
}

.headerIcon {
    font-size: 24px;
    margin: 0 10px;
    color: var(--headerFont);

}
.icon {
    position: absolute;
    top: 70%;
    left: 70%;
    transform: translateX(-50%) translateY(-50%);
    color: var(--headerFont);
}

.logo-banner {
    /* Play around with these properties to place the logo as you like */
    position: absolute;
    left: 0;
    top: 0;
    padding: 10px;
}

.logo {
    /* Adjust the width and height as desired */
    width: auto;
    height: 175px;
}

/*.mainmenu {*/
/*    display: flex;*/
/*    align-items: center;*/
/*}*/

/*.mainMenuButton {*/
/*    text-align: center;*/
/*    width: 100%;*/
/*}*/

/*.mainmenu__list {*/
/*    display: flex;*/
/*    list-style: none;*/
/*    margin: 0;*/
/*    padding: 0;*/
/*}*/

/*.mainmenu__item {*/
/*    margin-right: 1rem;*/
/*}*/

/*.mainmenu__link {*/
/*    text-decoration: none;*/
/*    color: var(--headerFont);*/
/*    transition: color 0.3s ease;*/
/*}*/

/*.mainmenu__list--open {*/
/*    display: block;*/
/*}*/

/*.mainmenu__toggle {*/
/*    display: none;*/
/*}*/

/*.mainmenu__toggle-label {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    cursor: pointer;*/
/*    padding: 0.5rem;*/
/*}*/

/*.mainmenu__toggle-icon {*/
/*    display: block;*/
/*    width: 30px;*/
/*    height: 2px;*/
/*    background-color: var(--headerBackground);*/
/*    transition: background-color 0.3s ease;*/
/*}*/

/*.menu {*/
/*    pointer-events: none;*/
/*}*/


/*.menuButton{*/
/*    height: 150px;*/
/*}*/


/*.menu__listings {*/
/*    z-index: 10;*/
/*    position: absolute;*/
/*    top: -210px;*/
/*    left: -310px;*/
/*    width: 400px;*/
/*    height: 400px;*/
/*    border-radius: 50%;*/
/*    transform: scale(0.1) rotate(150deg);*/
/*    transition: transform 1s;*/
/*}*/

/*.menu__toggle {*/
/*    z-index: 13;*/
/*    position: absolute;*/
/*    top: -100px;*/
/*    left: -210px;*/
/*    width: 200px;*/
/*    height: 200px;*/
/*    background-color: var(--headerBackground);*/
/*    border-radius: 50%;*/
/*    transition: transform 1.3s;*/
/*    cursor: pointer;*/
/*    pointer-events: auto;*/
/*}*/

/*.navbar {*/
/*    top: 0;*/
/*    width: 100%;*/
/*    z-index: 8888;*/
/*}*/

/*.navbar-container {*/
/*    height: 60px;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    background-color: var(--headerBackground);*/
/*}*/

/*.navbar-toggler {*/
/*    width: 50%;*/
/*    height: 60px;*/
/*    margin: -8px auto auto;*/
/*    color: var(--headerFont);*/
/*    border: none;*/
/*    border-radius: 0 !important;*/
/*    outline: none;*/
/*    overflow-y: auto;*/
/*}*/

/*.navbar-toggler:hover {*/
/*    background-color: var(--hover);*/
/*    border: none !important;*/
/*    box-shadow: none;*/
/*    outline: none;*/
/*}*/

/*.navbar-toggler:focus {*/
/*    border: none !important;*/
/*    outline: none;*/
/*    box-shadow: none !important;*/
/*    border-color: transparent !important;*/
/*}*/

/*.navbar-expand {*/
/*    width: 100%;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    background-color: transparent;*/
/*}*/

/*.navbar-collapse {*/
/*    justify-content: center;*/
/*    background-color: var(--headerBackground);*/
/*    width: 100%;*/

/*}*/

.placeholder {
    background-color: transparent !important;
}

.menu-container {
    position: absolute;
    margin-left: auto;
    top: 0;
    right: 0px;
    display: flex;
    align-items: center;
    z-index: 10001;
}

.upside {
    background-color: transparent !important;
}

.username {
    margin-top: 10px;
    margin-right: 15px;
    text-decoration: none;
    font-size: 45px;
    font-weight: bold;
    color: var(--headerFont);
    float: right;
    display: block;
}

.username:hover {
    color: var(--headerFont); /* Replace with the desired color or initial value */
}

#sub-logo {
    /*align-self: flex-start;*/
    /*padding-top: 60px;*/
    display: flex;
    align-items: flex-end;
    height: 100%;
    width: 50%;
    padding-left: 20px;
    /*padding-top: 60px;*/
}

.subLogo {
    /*display: flex !important;*/
    /*align-items: flex-end !important;*/
    height: 100%;
    width: 0;
    padding-left: 20px;
    /*padding-top: 60px;*/
}

@keyframes rotateMenu {

    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(180deg);
    }
}

@media (max-width: 1280px) {

    .username {
        display: none;
    }

}

@media (max-width: 700px) {

    /*#sub-logo {*/
        /*align-self: flex-start;*/
        /*padding-top: 60px;*/
        /*display: flex;*/
        /*align-items: flex-end !important;*/
        /*height: 100%;*/
        /*width: 50%;*/
        /*padding-left: 20px;*/
        /*padding-top: 60px;*/
    /*}*/

    .subLogo {
        /*display: flex !important;*/
        /*align-items: flex-end !important;*/
        /*height: 100%;*/
        width: 0;
        /*padding-left: 20px;*/
        /*padding-top: 60px;*/
    }

    #contact-menu {
        display: none;
        align-self: flex-end;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 50%;
    }

    .header-logo {
        width: 130px;
        /*height: auto;*/
    }

    #sub-logo {
        /*padding-top: 30px;*/
        display: none;
        width: 0;
        color: #201e1e
    }

    #contact-menu {
        align-self: flex-end;
        width: 100%;
        /*margin-top: -50px;*/
    }

    .header-container {
        /*position: fixed;*/
        /*top: 0;*/
        /*width: 100%;*/
        height: 100px; /* Usual height of 210 px
        /*background-color: transparent;*/
        /*background-color: #201e1e;*/
        /*z-index: 9999;*/
    }


    /*.arrow {*/
    /*    border-color: transparent;*/
    /*}*/

    /*.customDropdown {*/
    /*    color: var(--headerFont);*/
    /*    width: 100%;*/
    /*    background-color: var(--headerBackground);*/
    /*    text-align: center;*/
    /*}*/

    /*.customToggle {*/
    /*    color: var(--headerFont);*/
    /*    padding: 0 0;*/
    /*    width: 100%;*/
    /*       background-color: var(--headerBackground);*/
    /*   }*/
    /*.dropdown-menu {*/
    /*    background-color: var(--headerBackground) !important;*/
    /*    border-radius: 0 !important;*/
    /*    border: none !important;*/
    /*    width: 100%;*/
    /*}*/

    /*.navbar-collapse {*/
    /*    background-color: transparent;*/
    /*}*/

    /*.mainmenu__link {*/
    /*    color: var(--headerFont);*/
    /*}*/

    /*.mainmenu__list {*/
    /*    display: none;*/
    /*}*/


    /*.mainmenu__list--open {*/
    /*    display: block;*/
    /*}*/

    /*.mainmenu__toggle {*/
    /*    display: inline-block;*/
    /*}*/

    /*.mainmenu__toggle-icon {*/
    /*    background-color: var(--headerFont);*/
    /*}*/

    /*.username {*/
    /*    display: none;*/
    /*}*/
}