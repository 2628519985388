@import '~@fortawesome/fontawesome-free/css/all.min.css';

:root{
    --footerBackground: transparent;
    --footerColor:#ffffff;
    --footerHover: #595959;
    --footerCopyrightTxt:#393939;
    --footerIcons: #3a3a3a;
    --footerFont: #313131;
    --menuButtonBackground: rgba(66, 66, 66, 0.2);
    --menuButtonBorder: rgba(66, 66, 66, 0.5);
    --menuButtonContainer: rgba(241, 241, 241, 0.5);
}



/*.content{*/
/*    position: absolute;*/
/*    text-align: right;*/
/*    margin: -10px 0 0 -30px;*/
/*    top: 70%;*/
/*    left: 50%;*/
/*    font-size: 20px;*/
/*}*/

.copyright {
    color: var(--footerFont);
}

.footer {
    position: fixed;
    bottom: 0;
    ;
    /*left: 0;*/
    background-color: #FFFFFF;
    width: 100%;
    height: 100px;
    /*background-color: var(--footerBackground);*/
    color: var(--footerFont);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.footer-container {
    /*padding-top: 200px;*/
    /*background-color: #FFFFFF;*/
    /*position: fixed;*/
    /*right: 50px;*/
    /*bottom: 50px;*/
    z-index: 9999;
}

.footer a {
    text-decoration: none;
}

.footer a:hover .footerIcon {
    color: var(--hover);
}

.footer p {
    margin: 10px 0;
    font-size: 14px;
}

.footerIcon {
     font-size: 50px;
     margin: 0 10px;
     color: var(--footerIcons);
    z-index: 9999;
 }

.footerIcon:hover {
    color: var(--footerHover);
}

/*.footer-icons {*/
/*      display: flex;*/
/*      padding: 15px;*/
/*  }*/

/*.footer-container {*/
    /*position: fixed;*/
    /*right: 50px;*/
    /*bottom: 50px;*/
/*}*/

/*#menu {*/
/*    font-size: 200%;*/
/*    list-style: none;*/
/*    margin: 0;*/
/*    padding: 0;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    align-items: flex-end;*/
/*}*/


/*.menu-button {*/

/*    opacity: 0;*/
/*    z-index: -1;*/
/*    width: 100px;*/
/*    height: 100px;*/
/*    position: absolute;*/
/*    left: 50%;*/
/*    top: 50%;*/
/*    margin: -75px 0 0 -75px;*/
/*    border-radius: 50%;*/
/*    background-color: var(--menuButtonBackground);*/
/*    box-shadow: 0 0 20px var(--menuButtonBorder);*/
/*    backdrop-filter: blur(10px);*/
/*    -webkit-backdrop-filter: blur(10px);*/
/*    overflow: hidden;*/
/*    text-decoration: none;*/
/*}*/

/*.menu-container {*/
/*    position: fixed;*/
/*    bottom: 0;*/
/*    left: 0;*/
/*    width: 100%;*/
/*    height: 0;*/
/*    background-color: var(--menuButtonContainer);*/
/*    backdrop-filter: blur(5px);*/
/*    overflow: hidden;*/
/*}*/

/*.menu-container.open {*/
/*    height: 100%; !* Adjust the height as needed *!*/
/*}*/

/*.menu-item {*/
/*    color: transparent;*/
/*    width: 500px;*/
/*    height: 50px;*/
/*    position: absolute;*/
/*    line-height: 5px;*/
/*    bottom: 25%;*/
/*    right: 0;*/
/*    margin-right: 0;*/
/*    opacity: 0.5;*/
/*    text-align: right;*/
/*    align-items: center;*/
/*    align-content: center;*/
/*    background-color: transparent;*/
/*    transform: translate(0px, 0px);*/
/*    z-index: -2;*/
/*    transition: 500ms;*/
/*    cursor: pointer;*/
/*}*/

/*.menu-item:hover{*/
/*    opacity: 1;*/
/*}*/

/*.menu-item a {*/
/*    color: var(--footerFont);*/
/*    position: relative;*/
/*    top: 30%;*/
/*    right: 0px;*/
/*    text-decoration: none;*/
/*}*/

/*#menu:not(:target)>a:first-of-type,*/
/*#menu:target>a:last-of-type {*/
/*    opacity: 1;*/
/*    z-index: 1;*/
/*}*/

/*#menu:not(:target)>.icon-plus:before,*/
/*#menu:target>.icon-minus:before {*/
/*    opacity: 1;*/
/*}*/

/*#menu:target>.menu-item:nth-child(8) {*/
/*    transform: rotate(0deg) translateY(-450px) rotate(0deg);*/
/*    color: var(--footerFont);*/
/*}*/

/*#menu:target>.menu-item:nth-child(7) {*/
/*    transform: rotate(0deg) translateY(-375px) rotate(0deg);*/
/*    color: var(--footerFont);*/
/*}*/

/*#menu:target>.menu-item:nth-child(6) {*/
/*    transform: rotate(0deg) translateY(-300px) rotate(0deg);*/
/*    color: var(--footerFont);*/
/*}*/

/*#menu:target>.menu-item:nth-child(5) {*/
/*    transform: rotate(0deg) translateY(-225px) rotate(0deg);*/
/*    color: var(--footerFont);*/
/*}*/

/*#menu:target>.menu-item:nth-child(3) {*/
/*    transform: rotate(0deg) translateY(-150px) rotate(0deg);*/
/*    color: var(--footerFont);*/
/*}*/

/*#menu:target>.menu-item:nth-child(4) {*/
/*    transform: rotate(0deg) translateY(-75px) rotate(0deg);*/
/*    color: var(--footerFont);*/
/*}*/

/*.page-content {*/
/*    !*transition: transform 0.01s ease;*!*/
/*}*/


/*.page-content.open-menu {*/
/*    transform: translateY(0); !* Adjust the value to match the menu height *!*/
/*}*/


@media (max-width: 768px) {

    .menu-button {
        margin: -90px 0 0 -45px;
        width: 75px;
        height: 75px;

    }



}