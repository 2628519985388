
a {
  color: var(--hover) !important;
}
body {
  background-color: #FFFFFF !important;
  padding-top: 200px;
}

.avatar {
  vertical-align: middle;
  width: 500px;
  height: 500px;
  border-radius: 50%;
}

.bootstrap-select {
  max-width: 200px;
  /*width: 400px;*/
}
.bootstrap-select .btn {
  font-size: 24px;
  font-weight: 700;
  border-radius: 15px;
  width: 247%;
  /*height: 100px;*/
  /*background-color: var(--foreground);*/
  background-color: var(--foreground);
  border-style: solid;
  border-color: var(--primary);
  border-width: 2px;
  text-align: center;
  align-items: center;
  text-align-all: center;
  color: var(--primary);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.bootstrap-select .dropdown-menu {
  margin: 15px 0 0;
}

/*This is BorderedComponent*/
.bordered-component {
  position: relative;
  padding: 25px;
  margin: 15px;
  border: 2px solid var(--divider);
  border-radius: 5px;
  display: flex; /* Use Flexbox to center the name */
  justify-content: left; /* Center the name horizontally */
  flex-direction: column; /* Stack the name on top of the content */
  height: 100%;
  width: 100%;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.2);
}

.bordered-name {
  position: absolute;
  top: -17px;
  left: 50%;
  transform: translateX(-50%); /* Center the name in the middle */
  background-color: var(--formBackground);
  padding: 0 10px;
  font-size: 18px;
  white-space: nowrap;
}

.bordered-content {

}

.btn-container {
  margin-top: 100px;  /* This will push the div to the bottom of the flex container */
  align-self: flex-end;  /* This will align the button to the right */
  padding: 10px; /* Add some space around the button */
}

.btn-primary {
  /*background-color: var(--btnSecondaryColor);*/
  /*border-radius: 5px;*/
  /*border-color: var(--btnSecondaryBorder);*/
  /*border-width: 3px;*/
  /*color: var(--btnSecondaryFont);*/
  /*width: 250px;*/
  /*height: 40px;*/
  /*align-self: flex-end;*/
  /*font-size: 16px;*/
  /*border: none;*/
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: var(--main_font);
  font-weight: 700;
  font-size: 25px;
  color: var(--primary);
  background-color: var(--btnPrimaryColor);
  border-color: var(--btnPrimaryColor);
  border-width: 2px;
  border-radius: 15px;
  width: 100%;
  height: 50px;
  outline: none;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active, .btn-primary.active, .open>.dropdown-toggle.btn-primary {
  color: var(--hover);
  border-width: 3px;
  background-color: var(--foreground);
  border-color: var(--background); /*set the color you want here*/
  -webkit-box-shadow: none !important;
}

.btn-secondary {
  background-color: var(--btnSecondaryColor) !important;
  border-radius: 0 !important;
  border-color: var(--btnSecondaryBorder)!important;
  color: var(--btnSecondaryFont);
  width: 100%;
  height: 60px
}

.button {
  margin: 25px 5px 5px;
  width: 250px;
  border: none;
  outline: none;
  background-color: var(--background);
  color: var(--font);
  padding: 12px;
  cursor: pointer;
  border-radius: 0;
  font-weight: bold;
}

.card {
  display: inline-block;
  width: 300px;
  height: 580px;
  margin: 15px;
  border-width: 2px;
  border-color: black;
  border-radius: 15px;
  background-color: transparent;
  /*transform: rotate(90deg);*/
}

.card-name {
  display: inline-block;
  width: 300px;
  height: 50px;
  margin-right: 15px;
  margin-left: 15px;
  margin-bottom: -15px;
  text-align: center;
}

.carousel-item {
  height: 100vh;
  min-height: 350px;
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.clear {
  margin: 25px 0 0 0;
}

.clear1 {
  margin: 50px 0 0 0;
}

.clear2 {
  margin: 100px 0 0 0;
}

.clear3 {
  margin: 150px 0 0 0;
}

.clear4 {
  margin: 200px 0 0 0;
}

.clear5 {
  margin: 250px 0 0 0;
}

.clear6 {
  margin: 300px 0 0 0;
}

.clear7 {
  margin: 350px 0 0 0;
}

.clear8 {
  margin: 400px 0 0 0;
}

.clear9 {
  margin: 450px 0 0 0;
}

.clear10 {
  margin: 500px 0 0 0;
}

.clear12 {
  margin: 600px 0 0 0;

}

.close {
  color: var(--background);
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: var(--hover);
  text-decoration: none;
  cursor: pointer;
}

.custom-modal-header .close {
  color: white; /* Change the color to white */
  opacity: 1; /* Make sure the button is fully visible */
}

.custom-section {
  text-align: center;
  width: 100%;
  background-color: var(--background);
  padding: 50px;
  /*width: 80%;*/
}

/*#containerSection {*/
/*  text-align: center;*/
/*  padding: 50px;*/
/*  !*width: 80%;*!*/
/*}*/

/*#blurbSection {*/
/*  text-align: center;*/
/*  padding: 50px;*/
/*  !*width: 80%;*!*/
/*}*/

#mapSection {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
  padding: 50px;
  /*width: 80%;*/
}

#googleSection {
  text-align: center;
  padding: 50px;
  /*width: 80%;*/
}

/*#modificationSection{*/
/*  text-align: center;*/
/*  padding: 50px;*/
/*  !*width: 80%;*!*/
/*}*/

/*#containerHireSection {*/
/*  text-align: center;*/
/*  padding: 50px;*/
/*  margin-bottom: 20px;*/
/*  !*width: 80%;*!*/
/*}*/

/*#salesSection {*/
/*  text-align: center;*/
/*  padding: 50px;*/
/*  !*width: 80%;*!*/
/*}*/

.container-home {
  height: 100vh;
  width: 100%;
  padding: 2% 15% 0 15%;
  /*display: flex;*/
  position: relative;

}

.container-titles .container-title {
  margin: 0 5px;
  padding: 5px 10px;
  /*cursor: pointer;*/
  /*display: inline;*/
  font-size: 24px;
  font-weight: bold;
  /*color: #007bff; !* Bootstrap primary color for reference *!*/
  text-decoration: none;
}

.container-titles .container-title:hover,
.container-titles .container-title:focus {
  /*text-decoration: underline;*/
  /*color: #0056b3; !* Bootstrap primary color darkened for hover effect *!*/
}

.container-images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
  /*padding: 20px;*/
}

.container-image {
  /*width: calc(50% - 20px);*/
  width: 40%;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  transition: transform 0.3s ease-in-out;
}

.container-image:hover {
  transform: scale(1.05);
}

.single-image {
  width: 80%;
  height: auto;
  margin: 10px;
  display: block;
}

.modification-category h2 {
  margin-top: 20px;
  color: #333; /* Or any color that fits your theme */
}

.modification-category p, .modification-category ul {
  text-align: left;
  display: inline-block;
  max-width: 600px; /* Or adjust to fit your design */
}

.modification-category ul {
  list-style-type: none; /* This removes the default bullet points */
  padding: 0;
}

.modification-category li {
  margin-top: 10px;
}

.fli {
  font-family: var(--sec_font);
  font-weight: 600;
  font-size: 28px;
  color: var(--primary);

}

.form-check-label {
  font-family: 'Exo 2', sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: var(--primary)
}

.form-control {
  font-size: 24px;
  font-weight: 700;
  color: var(--primary);
  border-color: var(--primary);
  border-radius: 15px;
  background-color: var(--secondary);

}

.form, input, label, p {
  color: var(--primary) !important;
}

/*.form-control::text { !* Chrome, Firefox, Opera, Safari 10.1+ *!*/
/*  color: var(--primary);*/
/*  opacity: 1; !* Firefox *!*/
/*}*/

/*.form-control:-ms-input-text { !* Internet Explorer 10-11 *!*/
/*  color: var(--primary);*/
/*}*/

/*.form-control::-ms-input-text { !* Microsoft Edge *!*/
/*  color: var(--primary);*/
/*}*/

.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: slategrey;
  opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: var(--primary);
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
  color: var(--primary);
}


.form-control:focus {
  border-color: var(--primary);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px rgba(0, 0, 0, 0.6);
}

.form-select {

  font-family: var(--main_font);
  font-weight: 300;
  border-width: 2.5px;
  border-radius: 5px;
  border-color: var(--primary);
  /*height: 50px*/
}

.form-control:focus {
  outline: none;
  border-color: var(--secondary);
  box-shadow: 0 0 0px var(--primary);
}

.form-control {
  /*color: var(--secondary);*/
  background-color: var(--secondary);
  font-family: var(--main_font);
  font-weight: 300;
  border-width: 2.5px;
  border-radius: 5px;
  border-color: var(--primary);
  height: 50px
}

h1 {
  font-family: var(--main_font);
  font-weight: 400;
  font-size: 20px;
  color: var(--h1_col);
  margin-left: 0px;
  margin-top: 0px;
}


h2 {

  font-family: var(--main_font);
  font-weight: 600;
  font-size: 20px;
  color: var(--h2_col);
  margin-left: 0px;
  margin-top: 5px;
}

h3 {

  font-family: var(--main_font);
  font-weight: 600;
  font-size: 40px;
  color: var(--h3_col);
  margin-left: 0px;
  margin-top: 0px;
}


h4 {

  font-family: var(--main_font);
  font-weight: 600;
  font-size: 40px;
  color: var(--h4_col);
  margin-left: 0px;
  margin-top: 0px;
}

h5 {

  font-family: var(--main_font);
  font-weight: 600;
  font-size: 40px;
  color: var(--h5_col);
  margin-left: 0px;
  margin-top: 0px;
}

h6{
  /*font-family: var(--main_font);*/
  /*font-weight: 600;*/
  font-size: 20px;
  color: var(--h6_col);
  /*margin-left: 0px;*/
  /*margin-top: 0px;*/
}

.h2-white {

  font-family: var(--main_font);
  font-weight: 600;
  font-size: 20px;
  color: white;
  margin-left: 0px;
  margin-top: 0px;
  margin-bottom: 2px;
}

.hidden_text {
  visibility: hidden;
  display: none
}

.blurb {
  /*text-align: ;*/
  font-size: 36px;
  /*color: #555;*/
  margin-top: 20px;
  /*font-family: var(--main_font);*/
  /*font-weight: 300;*/
  /*font-size: 18px;*/
  /*color: var(--primary);*/
  /*margin-left: 0px;*/
  /*margin-top: 0px;*/
  text-align: justify;

}

.input {
  font-size: 24px;
  display: flex;
}

.input-group-text {
  border-color: var(--primary);
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  background-color: var(--btnPrimaryColor);
  margin-left: 5%;
  border-width: 2px;
  border-right-width: 0px;

}

input[type="checkbox"], input[type="radio"] {
  margin: 0;
  padding: 0;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  font: inherit;
}

input:checked + .slider {
  background-color: var(--hoverColor);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--hoverColor);
}

input:checked + .slider:before {
  transform: translateX(22px);
}

/* Style for all text-type inputs */
input[type="text"] {
  width: 100%;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /*margin-top: 15px;*/
  margin-bottom: 15px;
}

/* Style for placeholder text */
input[type="text"]::placeholder {
  color: #999;
}

.inputText:focus {
  outline: none;
  border-color: var(--hoverColor);
  box-shadow: 0 0 5px var(--hoverColor);
}

/* Style for input on focus */
input[type="text"]:focus {
  /*outline: none;*/
  /*border-color: var(--hoverColor);*/
  /*box-shadow: 0 0 5px var(--hoverColor);*/
}

input[type="date"] {
  width: 100%;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
}

input[type="date"]::placeholder {
  color: #999;

}

input[type="date"]:focus {
  outline: none;
  border-color: var(--hoverColor);
  box-shadow: 0 0 5px var(--hoverColor);
}

input[type="time"] {
    width: 100%;
    padding: 5px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    /*margin-bottom: 15px;*/
    margin-bottom: 15px;
}

input[type="time"]::placeholder {
    color: #999;
}

input[type="time"]:focus {
    outline: none;
    border-color: var(--hoverColor);
    box-shadow: 0 0 5px var(--hoverColor);
}

input[type="number"] {
  width: 100%;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /*margin-bottom: 15px;*/
  margin-bottom: 15px;
}

input[type="number"]::placeholder {
  color: #999;
}

input[type="number"]:focus {
  outline: none;
  border-color: var(--hoverColor);
  box-shadow: 0 0 5px var(--hoverColor);
}

input[type="email"] {
  width: 100%;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  /*margin-bottom: 15px;*/
  margin-bottom: 15px;
}

input[type="email"]::placeholder {
  color: #999;
}

input[type="email"]:focus {
  outline: none;
  border-color: var(--hoverColor);
  box-shadow: 0 0 5px var(--hoverColor);
}

.inputBox {
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  outline: none;
  background-color: var(--background);
  color: var(--font);
  width: 100%;
  height: 50px;
  border-style: solid;
  border-radius: 5px;
  border-width: 3px;
  border-color: var(--border);
  padding: 5px 10px;
  text-align: center;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill{
  -webkit-text-fill-color: var(--primary) !important;

}

.justify-text {
  /*white-space: nowrap;*/
  text-align: justify;
  text-justify: inter-word;
  font-size: 30px;
  font-weight: normal;
  /*max-width: 500px; !* you can adjust this value to achieve the desired 'squareness' *!*/
  margin: 0 auto;
}

.label {
  font-family: var(--main_font);
  font-weight: 700;
  font-size: 18px;
  color: var(--primary)
}

li {
  font-family: var(--sec_font);
  font-size: 17px;
  font-weight: 700;
  color: var(--btnPrimaryColor)
}

.modal-backdrop {
  background-color: rgba(66, 66, 66, 0.5) !important;
}

.modal .modal-dialog {
    z-index: 1;
    padding-top: 100px;
    width: 100% !important;
    height: 100%;
    overflow: auto;
    max-width: 90%
}

.modal .modal-body {
    padding: 20px 16px;
    background-color: var(--modalBackground);
    color: var(--font);
    height: 100%;
    width: 100%;
    /*border-bottom-left-radius: 15px;*/
    /*border-bottom-right-radius: 15px;*/
    border-radius: 10px;
    /*animation: fadeEffect 1s; !* Fading effect takes 1 second *!*/
    box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.2);
}

.modal-content {
  background-color: var(--primary)!important;
  color: var(--fontSecondary)!important;
  margin: auto;
  /*margin-top: 5%;*/
  padding: 20px;
  /*border: 1px solid var(--border);*/
  border: none;
  width: 100%;
  height: 90%;
  max-width: 1500px;
}

.modal-header {
  border: none !important;
}

.modal-footer {
  border: none !important;
}

.msg-modal {
  color: var(--background);
  width: 500px;
  overflow: auto;
  background-color: var(--primary);
  border-radius: 10px;
}

.multi-answer {
  width: 50%;
  height: 40px;
  padding-left: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Patrick Hand', cursive;
  font-size: 25px !important;            /* Adjust font size */
  color: #17176e;
}

/* Reset the font for the placeholder */
.multi-answer::placeholder {
  /*font-family: Arial, Helvetica, sans-serif;*/
  font-size: 20px;
  color: rgba(0,0,0,0.4);
}

.page-common {
  z-index: 1;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* align content to the top */
  align-items: center;
  min-height: 80vh; /* It will at least be 80vh but can expand if content requires */
  width: 90%;
  font-size: 40px;
  font-weight: bold;
  margin: auto;
  padding: 100px;
  background-color: var(--formBackground);
  border-radius: 15px;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.3);
}

.qrCode {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 30px;
}

/* Hide the default radio input */
.radio-as-checkbox {
  display: none;
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
}

.radio-as-checkbox + label {
  font-size: 20px; /* Adjust this value according to your preference */
  /*display: flex;*/
  align-items: start;
  margin-left: 25px;
  align-content: start;
  align-self: start;
  text-align: left;

}

/* Style for the fake checkbox when radio is not selected */
.radio-as-checkbox + label:before {
  content: "";
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border: 2px solid #000;
  border-radius: 5px;
  vertical-align: middle;
}


/* Style for the fake checkbox when radio is selected */
.radio-as-checkbox:checked + label:before {
  content: "✓";
  display: inline-block;
  color: #17176e;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  border: 2px solid #000;
  background-color: #fff;
  text-align: center;
  vertical-align: middle;
  line-height: 1px;  /* Adjust this line to position the tick correctly */
  font-size: 40px;    /* You can adjust font-size if needed */
}
.react-select__control {
  width: 100%;
  padding: 5px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
}

.react-select__control:focus {
  outline: none !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.scrolling-wrapper {
  /*display: block;*/
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  /*width: 500px;*/
  /*height: 500px;*/
  margin-left: -25%;
  margin-right: -25%;
  /*transform: rotate(-90deg) translateY(-100px);*/
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.search-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-style: solid;
  border-radius: 5px;
  border-width: 3px;
  border-color: var(--border);
  background-color: var(--background);
  padding: 5px 10px;
  color: var(--font);
  margin: 0;
}

.search-button {
  border: none;
  outline: none;
  background-color: var(--font);
  color: var(--background);
  cursor: pointer;
  border-radius: 5px;
  width: 50px
}

.search-button:hover {
  background-color: var(--hover);
}

.search-input {
  flex-grow: 1;
  border: none !important;
  outline: none;
  padding: 5px;
  background-color: var(--background);
  color: var(--font);
  width: 100%;
  margin-bottom: 0px !important;
}

.search-input:focus {
  outline: none !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

select::-ms-expand {
  /* for IE 11 */
  display: none;
}

.select {
  border-color: var(--primary);
  border-bottom-left-radius: 30px;
  border-top-left-radius: 15px;
  background-color: var(--btnPrimaryColor);
  margin-left: 5%;
  border-width: 2px;
  border-right-width: 0px;
  width: 100%;
  padding: 5px;
  font-size: 24px;
  margin-bottom: 15px;
}
.center {
  text-align: center;
}

Select .input[type="text"]:focus {
  outline: none !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

select::placeholder {
  color: #999;
}

select:focus {
  outline: none;
  border-color: var(--hoverColor);
  box-shadow: 0 0 5px var(--hoverColor);
}

.slide-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
  margin-bottom: 15px;
}

.slide-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 28px;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  border-radius: 50%;
  transition: 0.4s;
}

/* Style the slider for the "on" state */
.slider.on {
  background-color: #8BC34A;
}

/* Style the round indicator for the "on" state */
.slider.on:before {
  background-color: #4CAF50;
}

/* Style for odd rows */
.table-header {
  background-color: var(--modalBackground);
}

.table-oddrow {
  background-color: var(--modalBackground);
  cursor: pointer;
}

.textarea-answer {
  padding: 25px;
  width: 95%;
  min-width: 500px;
  height: auto;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Patrick Hand', cursive;
  font-size: 25px !important;
  color: #17176e;
}

tr{
  color: black;
  cursor: pointer;
}

tr:hover{
  background-color: var(--hover);
  color: var(--fontSecondary);
  cursor: pointer;
}

.tr2:hover{
  background-color: transparent;
  color: black;
  cursor: default;
}

.trash-icon {
  color: #000000; /* Initial color */
  cursor: pointer;
}

.trash-icon:hover {
  color: red; /* Color on hover */
}

.widget-container > * {
  display: flex;
  width: 100%;
  padding: 10px;
}

.wrapper {
  min-height: 100%;
  margin-bottom: -50px;
}

/* Go from zero to full opacity */
@keyframes fadeEffect {
  from {opacity: 0;}
  to {opacity: 1;}
}

@media (max-width: 600px) {

  body {
    padding-top: 100px;
  }
  /*.center-mobile{*/
  /*  display:table;*/
  /*  margin:0 auto;*/
  /*}*/

  .blurb {
    font-size: 20px;
    margin-top: 20px;
    text-align: justify;
  }

  .container-home {
    height: 100vh;
    width: 100%;
    padding: 0;
    /*display: flex;*/
    /*position: relative;*/

  }

  .container-image {
    width:80%;
  }

  .single-image {
    width: 100%;

  }

  .custom-section {
    /*padding: 0;*/
    /*width: 80%;*/
  }

  .container-titles {
    display: none;

  }

  .container-images {
    padding: 0;
  }

}

